import { useMemo } from 'react';
import { useRouter } from 'next/navigation';

import { ApiMethods } from '@/constants/api';
import { KanbanCardType } from '@/interfaces/kanban-board';
import { Pathway, PathwayModule } from '@/interfaces/explore';
import { MODULE_PROGRESS_STATUS, UserPathwayModule } from '@/interfaces/user';
import { addPathwayToUser } from '@/server-actions/explore-page';
import { addRemainingPathwayModules, updatePathwayModuleStatus } from '@/server-actions/kanban-board';
import { useNotification } from '@/providers/notification-context';
import { createHeadersJson } from '@/services/request.service';

import { useApi } from './use-api';
import { useWindowDimensions } from './use-window-dimensions';

const headers = createHeadersJson();

export const usePathwayActions = (userPathways: Pathway[], userModules: UserPathwayModule[], pathway: Pathway) => {
  const { sendRequest } = useApi();
  const { openSuccessNotification } = useNotification();

  const router = useRouter();
  const { isSmallMobile } = useWindowDimensions();

  const isPathwayAdded = useMemo(
    () => userPathways?.some((userPathway) => userPathway.pathwayId === pathway.pathwayId),
    [pathway.pathwayId, userPathways],
  );

  const isAnyPathwayModuleAdded = useMemo(
    () => userModules.some((userModule) => userModule.pathwayId === pathway.pathwayId),
    [pathway.pathwayId, userModules],
  );

  const openModule = (chosenModule: PathwayModule) => {
    router.push(`/module/${chosenModule.moduleId}?isAssignment=false&pathwayId=${pathway.pathwayId}`);
  };

  const getSummativeAssessment = async () => {
    return await sendRequest(
      'sum-assessment/get-sum-assessment',
      ApiMethods.POST,
      JSON.stringify({ id: pathway.pathwayId, type: KanbanCardType.PATHWAY_ASSESSMENT }),
      headers,
    );
  };

  const openSummativeAssessment = () => {
    router.push(`/assessment-quiz?pathwayId=${pathway.pathwayId}`);
  };

  const getMissingModules = () => {
    const targetPathway = userPathways?.find((userPathway) => userPathway.pathwayId === pathway.pathwayId);

    return targetPathway?.modules.filter((module) => {
      return (
        !module.comingSoon &&
        !targetPathway?.addedModules.some((addedModule) => addedModule.moduleId === module.moduleId && !addedModule.hidden)
      );
    });
  };

  const handlePathwayWithCompletedModules = async () => {
    if (pathway.assessmentStatus === MODULE_PROGRESS_STATUS.COMPLETED) {
      // in this case the summative assessment is Done so we're gonna show the first module available
      openModule(pathway.modules[0]);
    } else if (pathway.assessmentStatus === MODULE_PROGRESS_STATUS.IN_PROGRESS) {
      // if the summative assessment is in progress, open it
      openSummativeAssessment();
    } else {
      const quizData = await getSummativeAssessment();
      if (quizData?.statusCode === 201 && quizData.data.assessment.length > 0) {
        // if the summative assessment is not started but exists, open it
        openSummativeAssessment();
      } else {
        // in this case there's no summative assessment so we're gonna show the first module available
        openModule(pathway.modules[0]);
      }
    }
  };

  const handleAddPathwayToUser = async (start = false, userInTrial = false): Promise<void> => {
    if (isPathwayAdded) {
      const missingModules = getMissingModules();
      if (!missingModules?.length) return;
      await addRemainingPathwayModules({ pathwayId: pathway.pathwayId, modules: missingModules });
    } else {
      let availableModules = pathway.modules.filter((module) => !module.comingSoon);

      if (userInTrial) {
        // limit to the first 2 available modules for trial users
        availableModules = availableModules.slice(0, 2);
      }

      await addPathwayToUser({
        pathwayId: pathway.pathwayId,
        modules: availableModules,
        start,
      });
      openSuccessNotification(isSmallMobile ? 'Pathway added to your Board.' : `“${pathway.name}” was added to your Board.`);
    }
  };

  const startPathway = async (userInTrial = false) => {
    if (!isPathwayAdded) {
      await handleAddPathwayToUser(true, userInTrial);

      if (!isAnyPathwayModuleAdded && pathway.modules?.[0]) {
        openModule(pathway.modules[0]);

        return;
      }
    }

    if (!pathway.modules[0]) return;

    const getModuleByStatus = (status: MODULE_PROGRESS_STATUS) => {
      return pathway.modules.find(
        (module) => module.status === status && userModules.some((userModule) => userModule.moduleId === module.moduleId),
      );
    };

    let chosenModule = getModuleByStatus(MODULE_PROGRESS_STATUS.IN_PROGRESS);
    if (chosenModule) {
      // open the first module in Doing
      openModule(chosenModule);
    } else {
      chosenModule = getModuleByStatus(MODULE_PROGRESS_STATUS.NOT_STARTED);
      if (chosenModule) {
        // if we're opening a module in To Do, we have to change its status to Doing
        await updatePathwayModuleStatus({
          moduleId: chosenModule.moduleId,
          status: MODULE_PROGRESS_STATUS.IN_PROGRESS,
          hasQuiz: true,
          resetLessons: true,
          pathwayId: pathway.pathwayId,
        });

        openModule(chosenModule);
      } else {
        // all modules are in Done here
        await handlePathwayWithCompletedModules();
      }
    }
  };

  return { startPathway, handleAddPathwayToUser, isPathwayAdded };
};
